import React from 'react';
import PropTypes from 'prop-types';
import UpdateIcon from '@material-ui/icons/Cached';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, useMutation } from 'react-admin';

const PackageUpdateButton = ({
   record = {},
   icon = <UpdateIcon />,
   basePath,
   ...rest
}) => {
   const [updatePackage, { loading }] = useMutation({
      type: 'update',
      resource: 'packages',
      payload: { id: record.id },
   });
   return (
      <Button
         label="update"
         disabled={loading}
         onClick={(e) => {
            e.stopPropagation();
            updatePackage();
         }}
         {...rest}
      >
         {loading ? (
            <CircularProgress
               size={18}
               thickness={2}
            />
         ) : icon}
      </Button>
   );
};

PackageUpdateButton.propTypes = {
   basePath: PropTypes.string,
   className: PropTypes.string,
   classes: PropTypes.object,
   label: PropTypes.string,
   record: PropTypes.object,
   icon: PropTypes.element,
};

export default PackageUpdateButton;
