export const getEnablePackagePayload = (userId, packageId, packages) => () => {
   const formattedPackages = packages
      .map((pck) => ({ id: pck.id }));
   formattedPackages.push({ id: packageId });
   return {
      id: userId,
      data: {
         packages: formattedPackages,
      },
   };
};
export const getDisablePackagePayload = (userId, packageId, packages) => () => {
   const formattedPackages = packages
      .map((pck) => ({ id: pck.id }))
      .filter((pck) => pck.id !== packageId);
   return {
      id: userId,
      data: {
         packages: formattedPackages,
      },
   };
};
