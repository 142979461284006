import * as React from 'react';
import get from 'lodash/get';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const UrlField = ({ source, record = {} }) => {
   const value = get(record, source);
   if (!value) {
      return null;
   }
   return (
      <div>
         <span>{value}</span>
         <a href={value} target="_blank" rel="noopener noreferrer"><OpenInNewIcon style={{fontSize: '1rem'}}/></a>
      </div>
   );
};

UrlField.defaultProps = {
   addLabel: true,
};
export default UrlField;
