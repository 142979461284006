import * as React from 'react';
import get from 'lodash/get';

const DateField = ({ source, record = {} }) => {
   const value = get(record, source);
   if (!value) {
      return null;
   }
   const date = new Date(value);
   if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleString();
   }
   return '-';
};

DateField.defaultProps = {
   addLabel: true,
};
export default DateField;
