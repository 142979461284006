import get from 'lodash/get';

export const dataFormatterList = (resource, data) => {
   if (resource === 'users') {
      data.forEach((el) => {
         const packagesCount = el.packages.length;
         el.packagesCount = packagesCount;
         el.packages = el.packages.map((id) => ({ id }));
      });
   }
   return data;
};
export const dataFormatterOne = (resource, data) => {
   if (resource !== 'users') {
      return data;
   }
   const packagesCount = data.packages.length;
   data.packagesCount = packagesCount;
   return data;
};

export const clientFiltering = (params) => function (item) {
   let meetsFilters = true;
   for (const key of Object.keys(params.filter)) {
      if (typeof params.filter[key] === 'object') {
         for (const subkey of Object.keys(params.filter[key])) {
            const field = get(item, `${key}.${subkey}`) || '';
            meetsFilters = field.toLowerCase().includes(params.filter[key][subkey].toLowerCase());
         }
      } else if (typeof params.filter[key] === 'string') {
         const keys = key.split(',');
         for (const keyPart of keys) {
            const field = get(item, keyPart) || '';
            meetsFilters = field.toLowerCase().includes(params.filter[keys].toLowerCase());
            if (meetsFilters) {
               return true;
            }
         }
      }
   }
   return meetsFilters;
};
