import React from 'react';
import {
   SimpleShowLayout, TextField, useQueryWithStore, TitleForRecord,
} from 'react-admin';
import TableField from './TableField';
import VersionField from './VersionField';
import MarkdownField from './MarkdownField';
import DateField from './DateField';
import UrlField from './UrlField';
import Card from '@material-ui/core/Card';

const PackageShow = ({basepath, resource, id}) => {

   const { loaded: packageLoaded, error: packageError, data: packageData } = useQueryWithStore({
      type: 'getOne',
      resource: 'packages',
      payload: { id },
   });

   const { loaded: attrLoaded, error: attrError, data: attrData } = useQueryWithStore({
      type: 'getOne',
      resource: 'packages.attributes',
      payload: { id },
   });
   if (packageLoaded && attrLoaded) {
      const record = {
         ...attrData,
         ...packageData,
      };

      return (
         <Card>
            <TitleForRecord record={record} title={record.name} />
            <SimpleShowLayout basepath={basepath} record={record} resource={resource} version={1}>
               <TextField disabled label="Name" source="name" />
               <UrlField disabled label="Url" source="url" />
               <DateField disabled label="Last Updated" source="lastUpdated" />
               <VersionField disabled label="Versions" source="versions" />
               <TableField disabled label="Downloads" source="downloads" />
               <MarkdownField disabled label="Readme" source="readme" />
            </SimpleShowLayout>
         </Card>
      );
   }
   return 'Loading...';
};
export default PackageShow;
