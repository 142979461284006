import React from 'react';
import {
   List, Datagrid, TextField, ShowButton,
} from 'react-admin';
import PackagesFilter from './PackagesFilter';
import PackageUpdateButton from './PackageUpdateButton';

const PackagesList = (props) => (
   <List {...props} filters={<PackagesFilter />} bulkActionButtons={false}>
      <Datagrid>
         <TextField source="name" />
         <TextField source="url" />
         <ShowButton />
         <PackageUpdateButton />
      </Datagrid>
   </List>
);
export default PackagesList;
