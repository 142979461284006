import React from 'react';
import {
   TextInput,
   SimpleForm,
   Create,
   required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
   input: { minWidth: '500px' },
});

const Title = () => 'Add new package';

// eslint-disable-next-line consistent-return
const urlValidation = (value) => {
   const repoURLRegex = new RegExp('^\\s*https:\\/\\/bitbucket.org\\/[-a-zA-Z0-9@:%._\\+~#=\\/]+\\.git\\s*$');
   if (!repoURLRegex.test(value)) {
      return 'Must be a repository URL';
   }
};

const PackageAdd = (props) => {
   const classes = useStyles();
   const transform = (data) => {
      return ({
         ...data,
         repoUrl: data.repoUrl.trim(),
      });
   };
   return (
      <Create title={<Title />} {...props} transform={transform}>
         <SimpleForm redirect={'list'}>
            <TextInput source="repoUrl" validate={[required(), urlValidation]} className={classes.input} />
         </SimpleForm>
      </Create>
   );
};
export default PackageAdd;
