export function compareOneLevelDeep(fieldName, order) {
   return function (el1, el2) {
      const str1 = el1[fieldName];
      const
         str2 = el2[fieldName];
      if (order === 'ASC') {
         return str1.localeCompare(str2);
      }
      if (order === 'DESC') {
         return str2.localeCompare(str1);
      }
      return 0;
   };
}
