import {
   DELETE, fetchUtils, GET_LIST, GET_ONE, UPDATE, CREATE,
} from 'react-admin';
import { stringify } from 'query-string';
import { compareOneLevelDeep } from 'utils/comparison';
import { paginate } from 'utils/pagination';
import { dataFormatterList, dataFormatterOne, clientFiltering } from 'utils/response';

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVICE_URL : '';

const httpClient = fetchUtils.fetchJson;

const getHeaders = () => new Headers({
   'Content-Type': 'application/json',
   Authorization: localStorage.getItem('packagesAccessToken'),
});

const getResourcePath = (id) => ({
   'packages': `packages/${id}`,
   'users': `users/${id}`,
   'packages.attributes': `packages/${id}/attributes`,
});

const getList = (resource, params = {}) => {
   const { pagination = {}, sort = {} } = params;
   const { page, perPage } = pagination;
   const { field, order } = sort;
   const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
   };
   const url = `${apiUrl}/${resource}?${stringify(query)}`;

   return httpClient(url, {
      headers: getHeaders(),
   }).then(({ headers, json }) => {
      if (params.filter) {
         json = json.filter(clientFiltering(params));
      }
      const sorted = json.sort(compareOneLevelDeep(field, order));
      const data = paginate(sorted, page, perPage);

      return ({
         data: dataFormatterList(resource, data),
         total: json.length,
      });
   });
};

const getOne = (resource, params) => {
   const url = `${apiUrl}/${getResourcePath(params.id)[resource]}`;
   return httpClient(url, {
      headers: getHeaders(),
   }).then(({ headers, json }) => ({
      data: dataFormatterOne(resource, json),
   }));
};

const remove = (resource, params) => {
   const url = `${apiUrl}/${resource}/${params.id}`;
   return httpClient(url, {
      method: 'DELETE',
      headers: getHeaders(),
   }).then(() => ({
      data: params.previousData,
   }));
};

const create = (resource, params) => {
   const url = `${apiUrl}/${resource}`;
   return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
      headers: getHeaders(),
   }).then(({ headers, json }) => ({
      data: json,
   }));
};

const update = (resource, params) => {
   const url = `${apiUrl}/${resource}/${params.id}`;

   return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      headers: getHeaders(),
   })
      .then(() => getOne(resource, params));
};

export default (type, resource, params) => {
   const handlers = {
      [GET_LIST]: getList,
      [GET_ONE]: getOne,
      [UPDATE]: update,
      [DELETE]: remove,
      [CREATE]: create,
   };
   const handler = handlers[type];
   if (!handler) {
      throw new Error(`Dataprovider does not support the "${type}" operation`);
   }

   return handler(resource, params);
};
