import * as React from 'react';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';

const useVersionFieldStyles = makeStyles({
   container: { height: 300, marginTop: '1rem', overflowY: 'scroll' },
});

const VersionField = ({ source, record = {} }) => {
   const classes = useVersionFieldStyles();
   const value = get(record, source);
   if (!value) {
      return null;
   }
   return (
      <div className={classes.container}>
         {value.map((version) => <div key={version}>{version}</div>)}
      </div>
   );
};

VersionField.defaultProps = {
   addLabel: true,
};
export default VersionField;
