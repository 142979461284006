import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const useRowStyles = makeStyles({
   pckRow: {
      justifyContent: 'space-between',
      display: 'flex',
      '&:hover button': {
         opacity: '1',
      },
      '&:hover': {
         background: 'rgba(0, 0, 0, 0.02)',
      },
   },
   buttonInvisible: { opacity: '0' },
   buttonVisible: { opacity: '1' },
});

export const PackageRow = React.memo(({
   name, label, getTogglePayload,
}) => {
   const classes = useRowStyles();
   const [loading, setLoading] = useState(false);
   const notify = useNotify();
   const dataProvider = useDataProvider();

   const togglePackage = () => dataProvider
      .update('users', getTogglePayload())
      .then(() => {
         notify(`Saved "${name}"`);
      })
      .catch((error) => {
         notify(`Error saving package "${name}": ${error.message}`, 'warning');
      });

   return (
      <div
         className={classes.pckRow}
      >
         <Typography component="span">{name}</Typography>
         <Button
            onClick={() => {
               setLoading(true);
               togglePackage();
            }}
            className={loading ? classes.buttonVisible : classes.buttonInvisible}
            disabled={loading}
         >
            {loading && (
               <CircularProgress
                  size={22}
                  thickness={2}
               />
            )}
            {label}
         </Button>
      </div>
   );
});
