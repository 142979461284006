import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import { useQueryWithStore } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';
import { PackageRow } from './PackageRow';
import { getDisablePackagePayload, getEnablePackagePayload } from './packageTogglePayload';
import { LoadingCard } from './LoadingCard';

const useStyles = makeStyles({
   container: { minWidth: '50%', marginLeft: '1em' },
   loadingContent: { display: 'flex', justifyContent: 'center' },
   header: { marginBottom: '15px', marginTop: '15px' },
});

export const Aside = ({ record }) => {
   const classes = useStyles();
   const [query, search] = useState('');
   const packages = get(record, 'packages');
   const { data, loading } = useQueryWithStore({
      type: 'getList',
      resource: 'packages',
   });
   const debounceSearch = useRef(
      debounce((searchTerm) => {
         search(searchTerm);
      }, 400),
   );

   if (loading || !packages) {
      return <LoadingCard classes={classes} />;
   }

   const userPackages = packages ? packages.map((pck) => pck.id) : [];

   const disabled = data
      .filter((pck) => !userPackages.includes(pck.id))
      .filter((pck) => pck.name.includes(query))
      .sort((first, second) => get(first, 'name', '')
         .localeCompare(get(second, 'name', '')))
      .map((pck) => (
         <PackageRow
            key={pck.id}
            name={pck.name}
            label="Enable"
            getTogglePayload={getEnablePackagePayload(record.id, pck.id, packages)}
         />
      ));
   const enabled = data
      .filter((pck) => userPackages.includes(pck.id))
      .filter((pck) => pck.name.includes(query))
      .sort((first, second) => get(first, 'name', '')
         .localeCompare(get(second, 'name', '')))
      .map((pck) => (
         <PackageRow
            key={pck.id}
            name={pck.name}
            label="Disable"
            getTogglePayload={getDisablePackagePayload(record.id, pck.id, packages)}
         />
      ));

   const empty = <Typography>Not found</Typography>;
   return (
      <Card className={classes.container} elevation={2}>
         <CardContent>
            <TextField
               label="Search"
               onChange={(event) => {
                  const value = event.currentTarget.value.trim().toLocaleLowerCase();
                  debounceSearch.current(value);
               }}
            />
            <div>
               <Typography variant="h5" className={classes.header}>Enabled</Typography>
               {
                  enabled.length ? enabled : empty
               }
            </div>
            <div>
               <Typography variant="h5" className={classes.header}>Disabled</Typography>
               {
                  disabled.length ? disabled : empty
               }
            </div>
         </CardContent>
      </Card>
   );
};
