import * as React from 'react';
import get from 'lodash/get';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';

const useMarkdownFieldStyles = makeStyles({
   container: { height: 500, marginTop: '1rem', overflowY: 'scroll', backgroundColor: '#9c9b9b2e' },
});


const MarkdownField = ({ source, record = {} }) => {
   const classes = useMarkdownFieldStyles();
   const value = get(record, source);
   if (!value) {
      return null;
   }
   return (
      <div className={classes.container}>
         <ReactMarkdown>{value}</ReactMarkdown>
      </div>
   );
};
MarkdownField.defaultProps = {
   addLabel: true,
};

export default MarkdownField;
