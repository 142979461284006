import React from 'react';
import {
   Edit, required, SimpleForm, TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import UserTitle from './UserTitle';
import { Aside } from './Aside';

const useEditStyles = makeStyles({
   root: { alignItems: 'flex-start' },
});

const UserEdit = (props) => {
   const classes = useEditStyles();
   return (
      <Edit title={<UserTitle />} aside={<Aside record={props.record} />} classes={classes} {...props}>
         <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <TextInput source="username" validate={required()} />
            <TextInput source="password" />
            <TextInput multiline source="description" />
         </SimpleForm>
      </Edit>
   );
};
export default UserEdit;
