import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export const LoadingCard = React.memo(({ classes }) => (
   <Card className={classes.container} elevation={2}>
      <CardContent className={classes.loadingContent}>
         <CircularProgress
            className={classes.icon}
            size={30}
            thickness={2}
         />
      </CardContent>
   </Card>
));
