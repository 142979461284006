import * as React from 'react';
import get from 'lodash/get';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

const useTableFieldStyles = makeStyles({
   container: { height: 400, width: 500, marginTop: '1rem' },
});

const TableField = ({ source, record = {}, }) => {
   const classes = useTableFieldStyles();
   const value = get(record, source);
   const columns = [
      { field: 'user', headerName: 'User', width: 200 },
      { field: 'downloads', headerName: 'Downloads', width: 200 },
   ];
   const sortModel = [
      {
         field: 'downloads',
         sort: 'desc',
      },
   ];
   const rows = Object.keys(value)
      .map((key, index) => ({ id: index, user: key, downloads: value[key] }));
   return (
      <div className={classes.container}>
         <DataGrid rows={rows} columns={columns} pageSize={5} sortModel={sortModel} rowsPerPageOptions={[5, 15, 25]} />
      </div>
   );
};

TableField.defaultProps = {
   addLabel: true,
};
export default TableField;
