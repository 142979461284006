import React from 'react';
import {
   TextInput,
   SimpleForm,
   Create,
   required,
} from 'react-admin';


const UserTitle = () => 'Create new user';

const UserCreate = (props) => (
   <Create title={<UserTitle />} {...props}>
      <SimpleForm>
         <TextInput source="username" validate={required()} />
         <TextInput source="password" type="password" />
         <TextInput multiline source="description" />
      </SimpleForm>
   </Create>
);
export default UserCreate;
